"use client";

import { reportAnalyticsEvent } from "@/features/analytics/application/reportAnalyticsEvent";
import { AnalyticsMetadataKeys } from "@/features/analytics/domain/constants/AnalyticsMetadataKeys";
import { AnalyticsEventType } from "@/features/analytics/domain/entities/AnalyticsEventType";
import { anonymizeUserId } from "@/features/analytics/domain/entities/AnalyticsTools";

type ListItemClickMetadata = Record<
    | typeof AnalyticsMetadataKeys.userId
    | typeof AnalyticsMetadataKeys.listId
    | typeof AnalyticsMetadataKeys.listName
    | typeof AnalyticsMetadataKeys.listType
    | typeof AnalyticsMetadataKeys.listItemId
    | typeof AnalyticsMetadataKeys.listItemName
    | typeof AnalyticsMetadataKeys.listItemPosition
    | typeof AnalyticsMetadataKeys.listLength
    | typeof AnalyticsMetadataKeys.sort,
    string | number | undefined
>;

/**
 * Reports specific conversion events.
 */
export const reportListItemClick = (
    label: "discover-location-card",
    metadata: Partial<ListItemClickMetadata>
) => {
    void (async () => {
        // If there is a user id, anonymize it.
        const user_id = metadata[AnalyticsMetadataKeys.userId]
            ? await anonymizeUserId(
                  metadata[AnalyticsMetadataKeys.userId] as string
              )
            : undefined;

        void reportAnalyticsEvent({
            eventType: AnalyticsEventType.clickListItem,
            label,
            metadata: {
                ...metadata,
                label,
                ...(user_id ? { [AnalyticsMetadataKeys.userId]: user_id } : {}),
            },
        });
    })();
};
