import { useDiscoverMapStore } from "@/app/(main)/discover/[[...params]]/(application)/useDiscoverMapStore";
import { discoverMapListSplitViewTabletMaxWidthInPx } from "@/app/(main)/discover/[[...params]]/(components)/discover-map-constants";
import { HostLocationPageApi } from "@/app/(main)/discover/host/[hostLocationId]/pageApi";
import { hasValue } from "@/common/utilities/hasValue";
import { isNotEmptyStringOrMissingValue } from "@/common/utilities/isNotEmptyStringOrMissingValue";
import SmallLocationCardAlternative1 from "@/component-library/components/cards/location-cards/small-location-card-alternative-1/SmallLocationCardAlternative1";
import SmallLocationCardAlternative1Skeleton from "@/component-library/components/cards/location-cards/small-location-card-alternative-1/SmallLocationCardAlternative1Skeleton";
import WideLocationCardAlternative1 from "@/component-library/components/cards/location-cards/wide-location-card-alternative-1/WideLocationCardAlternative1";
import { reportListItemClick } from "@/features/analytics/application/reportListItemClick";
import { AnalyticsMetadataKeys } from "@/features/analytics/domain/constants/AnalyticsMetadataKeys";
import { useSelectedDuration } from "@/features/filtering/application/useSelectedDuration";
import { useSingleHostLocation } from "@/features/host-locations/application/useSingleHostLocation";
import { RatingsType } from "@/features/host-locations/domain/entities/RatingsSchema";
import { TSimplifiedHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/SimplifiedHostLocationWithWorkspaceGroupsSchema";
import { useSearchParams } from "next/navigation";
import React, { Fragment, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";

interface DiscoverListLocationCardProps {
    hostLocationWithWorkspaceGroups: TSimplifiedHostLocationWithWorkspaceGroupsSchema;
    doNotChangeHighlightedHostLocationIdOnHover?: boolean;
    priority?: boolean;
    position?: number;
    length?: number;
    sorting?: string;
    listId?: string;
    listName?: string;
}

const DiscoverListLocationCard: React.FC<DiscoverListLocationCardProps> = ({
    hostLocationWithWorkspaceGroups: _hostLocation,
    doNotChangeHighlightedHostLocationIdOnHover = false,
    priority,
    position,
    length,
    sorting,
    listId,
    listName,
}) => {
    const queryParams = useSearchParams();
    const { hours } = useSelectedDuration();
    const isTablet = useMediaQuery(
        `screen and (max-width: ${discoverMapListSplitViewTabletMaxWidthInPx}px)`,
        { initializeWithValue: true }
    );

    const { data: hostLocation, isLoading: isHostLocationLoading } =
        useSingleHostLocation(_hostLocation.location.id);

    const setHighlightedHostLocationId = useDiscoverMapStore(
        (state) => state.setHighlightedHostLocationId
    );

    const locationDetails = useMemo(() => {
        if (!hostLocation) {
            return null;
        }

        return {
            operatorName: isNotEmptyStringOrMissingValue(
                hostLocation.location.operator?.name
            )
                ? hostLocation.location.operator.name
                : undefined,
            locationTitle: hostLocation.location.name,
            pictures: hostLocation.location.pictures,
            currencyCode: hostLocation.location.currency.code,
            description:
                hostLocation.location.content ||
                hostLocation.location.description,
        };
    }, [hostLocation]);
    const lowestWorkspaceGroupPrice = useMemo(() => {
        const lowestPrice = _hostLocation.workspace_groups.reduce(
            (acc, group) => {
                if (!group.pricingInformation || !group.isMatch) {
                    return acc;
                }

                const rawPricing =
                    group.pricingInformation.lowestPrice.totalPrice *
                    (group.pricingInformation.lowestPrice.intervalLength ===
                    "HOUR"
                        ? hours
                        : 1);

                if (!acc) {
                    return rawPricing;
                }

                return Math.min(acc, rawPricing);
            },
            null as number | null
        );

        return lowestPrice;
    }, [_hostLocation.workspace_groups, hours]);
    const pricing = useMemo(() => {
        const rawPricing =
            _hostLocation.location.pricingInformation?.lowestPrice;

        if (!rawPricing) {
            return undefined;
        }

        return {
            hourlyPriceWithoutVat: rawPricing.hourly.totalPrice,
            totalPriceWithoutVat: lowestWorkspaceGroupPrice
                ? lowestWorkspaceGroupPrice
                : rawPricing.total?.totalPrice,
        };
    }, [
        _hostLocation.location.pricingInformation?.lowestPrice,
        lowestWorkspaceGroupPrice,
    ]);

    const overallRating = useMemo(() => {
        const rawOverallRating = hostLocation?.location.ratings
            ?.filter((rating) => rating.type === RatingsType.overall)
            .at(0);

        if (!hasValue(rawOverallRating)) {
            return null;
        }

        return {
            rating: rawOverallRating.value * 10,
            numberOfReviews: rawOverallRating.amount,
        };
    }, [hostLocation?.location.ratings]);

    const urlToLocationPage = useMemo(() => {
        if (!hasValue(hostLocation?.location.slug)) {
            return "#";
        }

        return HostLocationPageApi.getRoute({
            hostLocationId: hostLocation.location.slug,
            params: {
                ...Object.fromEntries(queryParams.entries()),
                position,
                length,
                sorting,
                "list-id": listId,
                "list-name": listName,
            },
        });
    }, [
        hostLocation?.location.slug,
        queryParams,
        position,
        length,
        sorting,
        listId,
        listName,
    ]);

    if (
        isHostLocationLoading ||
        !hasValue(hostLocation) ||
        !hasValue(locationDetails)
    ) {
        return (
            <SmallLocationCardAlternative1Skeleton key="discover-map-list-location-card-skeleton" />
        );
    }

    if (!hasValue(pricing)) {
        return <Fragment key="has-no-pricing" />;
    }

    const Card = isTablet
        ? SmallLocationCardAlternative1
        : WideLocationCardAlternative1;

    return (
        <Card
            options={{
                locationPageHref: urlToLocationPage,
                ctaLabel: `Book now`,
                dealLabel: _hostLocation.location.discounts.length
                    ? "DEAL"
                    : undefined,
                locationDetails: {
                    name: locationDetails.locationTitle,
                    operator: {
                        name: locationDetails.operatorName,
                    },
                    currency: {
                        code: locationDetails.currencyCode,
                    },
                    description: locationDetails.description,
                    address: `${
                        hostLocation.location.address.address_line_1
                            ? `${hostLocation.location.address.address_line_1}, `
                            : ""
                    }${hostLocation.location.address.city}`,
                },
                picture: {
                    pictureData: locationDetails.pictures.at(0),
                },
                rating: overallRating?.rating.toFixed(1),
                ratingNumber: overallRating?.rating,
                pricing,
                priority,
            }}
            onMouseEnter={
                doNotChangeHighlightedHostLocationIdOnHover
                    ? undefined
                    : () =>
                          setHighlightedHostLocationId(hostLocation.location.id)
            }
            onMouseLeave={
                doNotChangeHighlightedHostLocationIdOnHover
                    ? undefined
                    : () => setHighlightedHostLocationId(null)
            }
            onClick={() => {
                if (length === undefined || position === undefined) {
                    return;
                }
                reportListItemClick("discover-location-card", {
                    [AnalyticsMetadataKeys.listId]: "discover-list",
                    [AnalyticsMetadataKeys.listName]: "Discover List",
                    [AnalyticsMetadataKeys.listType]: "locations",
                    [AnalyticsMetadataKeys.listItemId]:
                        hostLocation.location.id,
                    [AnalyticsMetadataKeys.listItemName]:
                        hostLocation.location.name,
                    [AnalyticsMetadataKeys.listItemPosition]: position,
                    [AnalyticsMetadataKeys.listLength]: length,
                    [AnalyticsMetadataKeys.sort]: sorting,
                });
            }}
        />
    );
};

export default DiscoverListLocationCard;
