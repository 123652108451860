"use client";

import { useDiscoverMapStore } from "@/app/(main)/discover/[[...params]]/(application)/useDiscoverMapStore";
import DiscoverListLocationCard from "@/app/(main)/discover/[[...params]]/(components)/(cards)/DiscoverListLocationCard";
import {
    discoverListCardsWrapperStyle,
    discoverListHeaderContentStyle,
    discoverListHeaderStyle,
} from "@/app/(main)/discover/[[...params]]/(components)/(list)/DiscoverList.css";
import { mapQueryStateNamedLocationKey } from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import { discoverMapListSplitViewTabletMaxWidthInPx } from "@/app/(main)/discover/[[...params]]/(components)/discover-map-constants";
import { DiscoverViewSwitch } from "@/app/(main)/discover/[[...params]]/(components)/DiscoverViewSwitch";
import { reportInfo } from "@/common/application/debug";
import WideLocationCardAlternative1Skeleton from "@/component-library/components/cards/location-cards/wide-location-card-alternative-1/WideLocationCardAlternative1Skeleton";
import SmallLocationCardAlternative1Skeleton from "@/component-library/components/cards/location-cards/small-location-card-alternative-1/SmallLocationCardAlternative1Skeleton";
import { SortingSelect } from "@/component-library/components/sorting-select/SortingSelect";
import { WezooGeoConfig } from "@/configs/discover/WezooMapConfig";
import { useSelectedDuration } from "@/features/filtering/application/useSelectedDuration";
import { useSortedHostLocations } from "@/features/filtering/application/useSortedHostLocations";
import { convertParamToGeographicLocation } from "@/features/host-locations/application/convertParamToGeographicLocation";
import { parseAsString, useQueryState } from "nuqs";
import { discoverMapListEmptyStyle } from "src/app/(main)/discover/[[...params]]/(components)/(map)/DiscoverMapList.css";
import { useDiscoverMapContext } from "@/app/(main)/discover/[[...params]]/(components)/DiscoverMapProvider";
import { hasValue } from "@/common/utilities/hasValue";
import Hidden from "@/component-library/components/layout/hidden/Hidden";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { useFilteredHostLocations } from "@/features/filtering/application/useFilteredHostLocations";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import React, { useEffect, useMemo } from "react";
import LazyLoad from "react-lazy-load";
import { useMediaQuery } from "usehooks-ts";

interface DiscoverListProps {}

const MAX_NUMBER_OF_LOCATIONS_TO_DISPLAY = 30;

const DiscoverList: React.FC<DiscoverListProps> = () => {
    const {
        hostLocations: unfilteredLocations,
        hostLocationError: unfilteredLocationsError,
        isLoading,
    } = useDiscoverMapContext();

    const { filteredLocations } = useFilteredHostLocations({
        locations: unfilteredLocations,
    });
    const { hours } = useSelectedDuration();

    const { locations: sortedLocations, sorting } = useSortedHostLocations({
        locations: filteredLocations,
        filters: {
            hours,
        },
    });

    const [mapLocationFromQueryParam, setMapLocationFromQueryParam] =
        useQueryState(mapQueryStateNamedLocationKey, parseAsString);

    const { setMapBounds, setMapState } = useDiscoverMapStore((state) => state);

    const isTablet = useMediaQuery(
        `screen and (max-width: ${discoverMapListSplitViewTabletMaxWidthInPx}px)`,
        { initializeWithValue: true }
    );

    useEffect(() => {
        const helper = async () => {
            if (mapLocationFromQueryParam) {
                const geographicLocation =
                    await convertParamToGeographicLocation(
                        mapLocationFromQueryParam
                    );
                reportInfo("geographicLocation", geographicLocation);
                if (geographicLocation) {
                    setMapState({
                        currentPosition:
                            geographicLocation.coordinates.getCoordinate(),
                        zoom: geographicLocation.zoom,
                    });
                    if (geographicLocation.bbox) {
                        reportInfo(
                            "geographicLocation.bbox",
                            geographicLocation.bbox
                        );
                        // ensure the bbox is at least as big as
                        // WezooGeoConfig.bboxFromPointOffset
                        const bboxWidth =
                            geographicLocation.bbox[2] -
                            geographicLocation.bbox[0];
                        const bboxHeight =
                            geographicLocation.bbox[3] -
                            geographicLocation.bbox[1];
                        if (bboxWidth < WezooGeoConfig.bboxFromPointOffset) {
                            reportInfo("Expanding bbox width.");
                            geographicLocation.bbox[0] -=
                                (WezooGeoConfig.bboxFromPointOffset -
                                    bboxWidth) /
                                2;
                            geographicLocation.bbox[2] +=
                                (WezooGeoConfig.bboxFromPointOffset -
                                    bboxWidth) /
                                2;
                        }
                        if (bboxHeight < WezooGeoConfig.bboxFromPointOffset) {
                            reportInfo("Expanding bbox height.");
                            geographicLocation.bbox[1] -=
                                (WezooGeoConfig.bboxFromPointOffset -
                                    bboxHeight) /
                                2;
                            geographicLocation.bbox[3] +=
                                (WezooGeoConfig.bboxFromPointOffset -
                                    bboxHeight) /
                                2;
                        }

                        setMapBounds({
                            minLatitude: geographicLocation.bbox[1],
                            minLongitude: geographicLocation.bbox[0],
                            maxLatitude: geographicLocation.bbox[3],
                            maxLongitude: geographicLocation.bbox[2],
                        });
                    } else {
                        reportInfo(
                            "geographicLocation.coordinates",
                            geographicLocation.coordinates
                        );
                        const coords =
                            geographicLocation.coordinates.getCoordinate();
                        setMapBounds({
                            minLatitude:
                                coords.latitude -
                                WezooGeoConfig.bboxFromPointOffset,
                            minLongitude:
                                coords.longitude -
                                WezooGeoConfig.bboxFromPointOffset,
                            maxLatitude:
                                coords.latitude +
                                WezooGeoConfig.bboxFromPointOffset,
                            maxLongitude:
                                coords.longitude +
                                WezooGeoConfig.bboxFromPointOffset,
                        });
                    }
                    void setMapLocationFromQueryParam(null);
                }
            }
        };

        void helper();

        /**
         * We only care about changes in query params.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasLocationsVisibleOnTheMap = useMemo(() => {
        return hasValue(sortedLocations) && sortedLocations.length > 0;
    }, [sortedLocations]);

    if (unfilteredLocationsError) {
        throw unfilteredLocationsError;
    }

    const Skeleton = isTablet
        ? SmallLocationCardAlternative1Skeleton
        : WideLocationCardAlternative1Skeleton;

    return (
        <Flex
            direction="column"
            alignItems="stretch"
            gap={0}
            style={assignInlineVars({
                height: "100%",
                minHeight: "100%",
                overflowY: "scroll",
                margin: `0 auto`,
                paddingBottom: `${Magnitudes.spacingInRem.l}rem`,
                width: "100%",
            })}
        >
            <Flex
                direction="row"
                gap={Magnitudes.spacingInRem.xs}
                className={discoverListHeaderStyle}
                style={assignInlineVars({
                    ///position: "sticky",
                })}
                alignItems={"end"}
                justifyContent={"space-between"}
            >
                <div className={discoverListHeaderContentStyle}>
                    <div
                        style={assignInlineVars({
                            //marginLeft: `auto`,
                        })}
                    >
                        <SortingSelect />
                    </div>

                    <div
                        style={assignInlineVars({
                            marginLeft: `auto`,
                        })}
                    >
                        <DiscoverViewSwitch />
                    </div>
                    {/*<div*/}
                    {/*    style={assignInlineVars({*/}
                    {/*        minHeight: `${Magnitudes.spacingInRem.xxxl}rem`,*/}
                    {/*    })}*/}
                    {/*/>*/}
                    {/*<h2 className={discoverMapListHeaderHeadingStyle}>*/}
                    {/*    Locations in view*/}
                    {/*    <LoadingIndicator hidden={!isLoading} />*/}
                    {/*</h2>*/}
                    {/*<Hidden*/}
                    {/*    isShowing={!isLoading && !hasLocationsVisibleOnTheMap}*/}
                    {/*>*/}
                    {/*    <div>No locations found within the map view.</div>*/}
                    {/*</Hidden>*/}
                    {/*<Hidden isShowing={hasLocationsVisibleOnTheMap}>*/}
                    {/*    <div>*/}
                    {/*        {searchResultsInfo.numberOfLocations} locations with{" "}*/}
                    {/*        {searchResultsInfo.numberOfRooms +*/}
                    {/*            searchResultsInfo.numberOfHotDesks}{" "}*/}
                    {/*        matching workspaces.*/}
                    {/*    </div>*/}
                    {/*</Hidden>*/}
                </div>
            </Flex>
            <Flex direction="column" alignItems="stretch">
                <Hidden
                    className={discoverListCardsWrapperStyle}
                    isShowing={!!isLoading && !hasLocationsVisibleOnTheMap}
                >
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Hidden>
                <Hidden isShowing={!isLoading && !hasLocationsVisibleOnTheMap}>
                    <div className={discoverMapListEmptyStyle}>
                        Pan or zoom out to view more available locations.
                    </div>
                </Hidden>
                <Hidden
                    isShowing={hasLocationsVisibleOnTheMap}
                    className={discoverListCardsWrapperStyle}
                >
                    {sortedLocations
                        ?.filter((location) => location.isMatch)
                        .slice(0, MAX_NUMBER_OF_LOCATIONS_TO_DISPLAY)
                        .map((location, index) => {
                            return (
                                <LazyLoad
                                    //height={310}
                                    key={location.location.id}
                                >
                                    <DiscoverListLocationCard
                                        hostLocationWithWorkspaceGroups={
                                            location
                                        }
                                        // Load the first 4 cards immediately.
                                        priority={index < 4}
                                        position={index}
                                        length={sortedLocations.length}
                                        sorting={sorting || "default"}
                                        listId={"discover-list"}
                                        listName={"Discover List"}
                                    />
                                </LazyLoad>
                            );
                        })}
                </Hidden>
            </Flex>
        </Flex>
    );
};

export default DiscoverList;
