import { hasValue } from "@/common/utilities/hasValue";
import { isNotEmptyStringOrMissingValue } from "@/common/utilities/isNotEmptyStringOrMissingValue";
import { smallLocationCardAlternative2Style } from "@/component-library/components/cards/location-cards/small-location-card-alternative-2/SmallLocationCardAlternative2.css";
import SmallLocationCardAlternative2Skeleton from "@/component-library/components/cards/location-cards/small-location-card-alternative-2/SmallLocationCardAlternative2Skeleton";
import SmallLocationCardMap from "@/component-library/components/cards/location-cards/small-location-card-map/SmallLocationCardMap";
import { useSelectedDuration } from "@/features/filtering/application/useSelectedDuration";
import { useSingleHostLocation } from "@/features/host-locations/application/useSingleHostLocation";
import { RatingsType } from "@/features/host-locations/domain/entities/RatingsSchema";
import { TSimplifiedHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/SimplifiedHostLocationWithWorkspaceGroupsSchema";
import { WezooResourceTypeSchema } from "@/features/resource/resource-types/shared/WezooResourceType";
import React, { useMemo } from "react";

interface DiscoverMapTooltipProps {
    hostLocationWithWorkspaceGroups: TSimplifiedHostLocationWithWorkspaceGroupsSchema;
}

const DiscoverMapTooltip = ({
    hostLocationWithWorkspaceGroups,
}: DiscoverMapTooltipProps) => {
    const { hours } = useSelectedDuration();

    const { data: hostLocation, isLoading: isHostLocationLoading } =
        useSingleHostLocation(hostLocationWithWorkspaceGroups.location.id);

    const locationDetails = useMemo(() => {
        if (!hostLocation) {
            return null;
        }

        return {
            operatorName: isNotEmptyStringOrMissingValue(
                hostLocation.location.operator?.name
            )
                ? hostLocation.location.operator.name
                : undefined,
            locationTitle: hostLocation.location.name,
            pictures: hostLocation.location.pictures,
        };
    }, [hostLocation]);

    const numberOfMatchingRooms = useMemo(() => {
        // We count all rooms, and if there are hotdesks, we add one to the count.

        const hasMatchingHotdesks =
            hostLocationWithWorkspaceGroups.workspace_groups.filter(
                (group) => group.type === WezooResourceTypeSchema.enum.HOTDESK
            ).length > 0;

        return (
            hostLocationWithWorkspaceGroups.workspace_groups.reduce(
                (acc, group) => {
                    if (
                        group.type === WezooResourceTypeSchema.enum.ROOM &&
                        group.isMatch
                    ) {
                        return acc + 1;
                    }

                    return acc;
                },
                0
            ) + (hasMatchingHotdesks ? 1 : 0)
        );
    }, [hostLocationWithWorkspaceGroups.workspace_groups]);
    const lowestWorkspaceGroupPrice = useMemo(() => {
        const lowestPrice =
            hostLocationWithWorkspaceGroups.workspace_groups.reduce(
                (acc, group) => {
                    if (!group.pricingInformation || !group.isMatch) {
                        return acc;
                    }

                    const rawPricing =
                        group.pricingInformation.lowestPrice.totalPrice *
                        (group.pricingInformation.lowestPrice.intervalLength ===
                        "HOUR"
                            ? hours
                            : 1);

                    if (!acc) {
                        return rawPricing;
                    }

                    return Math.min(acc, rawPricing);
                },
                null as number | null
            );

        return lowestPrice;
    }, [hostLocationWithWorkspaceGroups.workspace_groups, hours]);

    const pricing = useMemo(() => {
        const rawPricing =
            hostLocationWithWorkspaceGroups.location.pricingInformation
                ?.lowestPrice;

        if (!rawPricing) {
            return undefined;
        }

        return {
            hourlyPriceWithoutVat: rawPricing.hourly.totalPrice,
            totalPriceWithoutVat: lowestWorkspaceGroupPrice
                ? lowestWorkspaceGroupPrice
                : rawPricing.total?.totalPrice,
        };
    }, [
        hostLocationWithWorkspaceGroups.location.pricingInformation
            ?.lowestPrice,
        lowestWorkspaceGroupPrice,
    ]);

    const overallRating = useMemo(() => {
        const rawOverallRating = hostLocation?.location.ratings
            ?.filter((rating) => rating.type === RatingsType.overall)
            .at(0);

        if (!hasValue(rawOverallRating)) {
            return null;
        }

        return {
            rating: rawOverallRating.value * 10,
            numberOfReviews: rawOverallRating.amount,
        };
    }, [hostLocation?.location.ratings]);

    if (isHostLocationLoading) {
        return <SmallLocationCardAlternative2Skeleton />;
    }

    if (!hasValue(hostLocation) || !hasValue(locationDetails)) {
        return (
            <div className={smallLocationCardAlternative2Style}>
                Something went wrong loading the location...
            </div>
        );
    }

    return (
        <SmallLocationCardMap
            options={{
                locationDetails: {
                    name: locationDetails.locationTitle,
                    operator: {
                        name: locationDetails.operatorName,
                    },
                    currency: {
                        code: hostLocation.location.currency.code,
                    },
                },
                dealLabel: hostLocation.location.discounts.length
                    ? "DEAL"
                    : undefined,
                picture: {
                    pictureData: locationDetails.pictures.at(0),
                },
                imageOverlays: {
                    rating: overallRating?.rating.toFixed(1),
                    secondaryBlock: `${numberOfMatchingRooms} matching options`,
                },
                pricing: {
                    totalPriceWithoutVat: pricing?.totalPriceWithoutVat,
                    hourlyPriceWithoutVat: pricing?.hourlyPriceWithoutVat,
                    pricingIsLoading: !hasValue(pricing),
                },
            }}
        />
    );
};

export default DiscoverMapTooltip;
